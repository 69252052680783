import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import { AgriPetBusinessData } from '../../parse-class/AgriPetBusinessData'

interface IFields {
  note: string;
}

interface IParams {
  id: string | null;
  onClose: () => void;
  onChange: () => void;
}

export default function ExcludeDialog(props: IParams) {

  const { id, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    note: '非合適客戶',
  })

  const verify = React.useMemo(() => {
    return fields.note.trim().length > 0
  }, [fields])

  const onSave = React.useCallback(async () => {
    if (id && verify) {
      try {
        backdrop.show('更新資料中')
        const query = new Parse.Query(AgriPetBusinessData)
        const raw = await query.get(id)
        raw.set('note', fields.note)
        raw.set('isExclude', true)
        await raw.save()
        snackbar.show('更新成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, verify, fields, onChange])

  return (
    <Dialog
      open={id !== null}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>排除資料</DialogTitle>
      <DialogContent>
        <BasicTextField
          label="排除原因"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}