import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'

export default function BasicTextField(textFieldProps: TextFieldProps) {

  return (
    <TextField
      margin="normal"
      variant="outlined"
      fullWidth
      {...textFieldProps}
    />
  )
}