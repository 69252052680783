import React from 'react'
import { useTheme } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

export default function FullHeightBox(props: BoxProps) {

  const theme = useTheme()

  const toolbarHeight = React.useMemo(() => {
    return theme.mixins.toolbar.minHeight || 0
  }, [theme])

  return <Box
    {...props}
    height={`calc(100vh - ${toolbarHeight}px)`}
  />
}