import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import BasicTextField from '../../input/BasicTextField'
import BasicNumberField from '../../input/BasicNumberField'
import CityAutocomplete from '../../input/CityAutocomplete'
import DistrictAutocomplete from '../../input/DistrictAutocomplete'
import { AgriPetBusinessData } from '../../parse-class/AgriPetBusinessData'
import { Customer } from '../../parse-class/Customer'

interface IFields {
  name: string
  city: string | null
  district: string | null
  address: string
  note: string
  lat: number
  lng: number
}

interface IParams extends DialogProps {
  addingPlaceId: string | null
  onUpdate: () => void
}

export default function CustomerAddDialog(props: IParams) {

  const { addingPlaceId, onUpdate, ...dialogProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [raw, setRaw] = React.useState<AgriPetBusinessData | null>(null)
  const [fields, setFields] = React.useState<IFields>({
    name: '',
    city: null,
    district: null,
    address: '',
    note: '',
    lat: 0,
    lng: 0,
  })

  const verify = React.useMemo(() => {
    return fields.name.trim().length > 0
      && fields.city !== null
      && fields.district !== null
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(AgriPetBusinessData)
      const data = await query.get(id)
      setRaw(data)
      setFields({
        name: data.get('name') || '',
        city: data.get('city') || null,
        district: '',
        address: data.get('address') || '',
        note: `由特寵業者名單匯入\n(${data.get('name')})`,
        lat: data.get('coordinate')?.latitude || 0,
        lng: data.get('coordinate')?.longitude || 0,
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (dialogProps.open && addingPlaceId !== null) {
      loadData(addingPlaceId)
    }
  }, [dialogProps.open, loadData, addingPlaceId])

  const onSave = React.useCallback(async () => {
    if (raw !== null && verify) {
      try {
        backdrop.show('新增資料中')
        const customer = new Customer()
        customer.set('name', fields.name)
        customer.set('city', fields.city || '')
        customer.set('district', fields.district || '')
        customer.set('address', fields.address)
        customer.set('contactName', '')
        customer.set('contactPhone', '')
        if (fields.lat !== 0 && fields.lng !== 0) {
          customer.set('position', new Parse.GeoPoint({ latitude: fields.lat, longitude: fields.lng }))
        }
        customer.set('note', fields.note)
        await customer.save()
        // 設定customer欄位
        raw.set('customer', customer)
        await raw.save()
        snackbar.show('新增成功')
        onUpdate() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('新增失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, verify, onUpdate])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>新增客戶</DialogTitle>
      <DialogContent>
        {/* 名稱 */}
        <BasicTextField
          required
          label="名稱"
          value={fields.name}
          onChange={e => { setFields(pv => ({ ...pv, name: e.target.value })) }}
          autoFocus
        />
        {/* 縣市 */}
        <CityAutocomplete
          textFieldProps={{ required: true }}
          value={fields.city}
          onChange={(event, value) => { setFields(pv => ({ ...pv, city: value, district: null })) }}
        />
        {/* 鄉鎮市區 */}
        <DistrictAutocomplete
          textFieldProps={{ required: true }}
          city={fields.city}
          value={fields.district}
          onChange={(event, value) => { setFields(pv => ({ ...pv, district: value })) }}
        />
        {/* 地址 */}
        <BasicTextField
          label="地址"
          value={fields.address}
          onChange={e => { setFields(pv => ({ ...pv, address: e.target.value })) }}
        />
        {/* 緯度 */}
        <BasicNumberField
          label="緯度"
          value={fields.lat}
          onChange={e => { setFields(pv => ({ ...pv, lat: Number(e.target.value) })) }}
        />
        {/* 經度 */}
        <BasicNumberField
          label="經度"
          value={fields.lng}
          onChange={e => { setFields(pv => ({ ...pv, lng: Number(e.target.value) })) }}
        />
        {/* 備註 */}
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
          multiline
          rows={7}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          新增
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}