import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import PlaceIcon from '@mui/icons-material/Place'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import EditIcon from '@mui/icons-material/Edit'
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import CustomerAddDialog from './CustomerAddDialog'
import EditDialog from './EditDialog'
import ExcludeDialog from './ExcludeDialog'
import { GoogleMapPlace } from '../../parse-class/GoogleMapPlace'

export const FIELDS = {
  CUSTOMER: 'CUSTOMER',
  IS_EXCLUDE: 'IS_EXCLUDE',
  IS_DELETE: 'IS_DELETE',
  PLACE_ID: 'PLACE_ID',
  NAME: 'NAME',
  CITY: 'CITY',
  STATE: 'STATE',
  NOTE: 'NOTE',
}

export function GoogleMapPlacePage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [dataList, setDataList] = React.useState<GoogleMapPlace[] | null>(null)
  //state
  const [addingId, setAddingId] = React.useState<string | null>(null)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [excludingId, setExcludingId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const dataList = await new Parse.Query(GoogleMapPlace)
        .limit(999999)
        .find()
      setDataList(dataList)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const copyPlaceId = React.useCallback(async (id: string) => {
    await navigator.clipboard.writeText(id)
    snackbar.show('已複製ID到剪貼簿')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const rows = React.useMemo(() => {
    if (!dataList) {
      return []
    }
    return dataList.map(data => (
      {
        id: data.id,
        [FIELDS.CUSTOMER]: data.get('customer'),
        [FIELDS.IS_EXCLUDE]: data.get('isExclude'),
        [FIELDS.IS_DELETE]: data.get('isDelete'),
        [FIELDS.PLACE_ID]: data.get('placeId'),
        [FIELDS.NAME]: data.get('name'),
        [FIELDS.CITY]: data.get('city'),
        [FIELDS.STATE]: data.get('businessStatus'),
        [FIELDS.NOTE]: data.get('note'),
      }
    ))
  }, [dataList])

  const columns: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: FIELDS.CUSTOMER,
        headerName: '建檔',
        type: 'boolean',
        width: 50,
        valueGetter: (value) => {
          return value !== undefined
        },
      },
      {
        field: FIELDS.IS_EXCLUDE,
        headerName: '排除',
        type: 'boolean',
        width: 50,
      },
      {
        field: FIELDS.IS_DELETE,
        headerName: '刪除',
        type: 'boolean',
        width: 50,
      },
      {
        field: FIELDS.NAME,
        headerName: '名稱',
        width: 200,
      },
      {
        field: FIELDS.CITY,
        headerName: '縣市',
        width: 85,
      },
      {
        field: FIELDS.STATE,
        headerName: '狀態',
        width: 85,
        valueFormatter: (v: string) => {
          switch (v) {
            case 'OPERATIONAL': return '營業中'
            case 'CLOSED_TEMPORARILY': return '停業'
            case 'CLOSED_PERMANENTLY': return '歇業'
          }
        }
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 200,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 200,
        getActions: ({ id, row }) => {
          const placeId: string = row[FIELDS.PLACE_ID]
          const customer: string = row[FIELDS.CUSTOMER]
          const isDelete: boolean = row[FIELDS.IS_DELETE]
          const isExclude: boolean = row[FIELDS.IS_EXCLUDE]
          return [
            <GridActionsCellItem
              icon={<PlaceIcon />}
              label="地圖"
              color="primary"
              onClick={() => {
                window.open(`https://www.google.com/maps/place/?q=place_id:${placeId}`, 'map_window', 'menubar:false,height=800,width=1200')
              }}
            />,
            <GridActionsCellItem
              icon={<ContentCopyIcon />}
              label="複製ID"
              color="primary"
              onClick={() => {
                copyPlaceId(placeId)
              }}
            />,
            <GridActionsCellItem
              disabled={customer !== undefined || isDelete || isExclude}
              icon={<AddIcon />}
              label="建立"
              color="primary"
              onClick={() => {
                setAddingId(id as string)
              }}
            />,
            <GridActionsCellItem
              disabled={customer !== undefined || isDelete || isExclude}
              icon={<NotInterestedIcon />}
              label="排除"
              color="primary"
              onClick={() => { setExcludingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              color="primary"
              onClick={() => {
                setEditingId(id as string)
              }}
            />
          ]
        }
      }
    ]
  }, [copyPlaceId])

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="Google Map Place" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              loading={dataList === null}
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
            />
          </Box>
        </StandardBox>
      </Container>
      <CustomerAddDialog
        open={addingId !== null}
        onClose={() => { setAddingId(null) }}
        fullWidth
        maxWidth="sm"
        addingPlaceId={addingId}
        onUpdate={() => {
          loadData()           // 載入新資料
          setAddingId(null)    // 關閉視窗
        }}
      />
      <EditDialog
        id={editingId}
        onClose={() => { setEditingId(null) }}
        onChange={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
      <ExcludeDialog
        id={excludingId}
        onClose={() => { setExcludingId(null) }}
        onChange={() => {
          loadData()            // 載入新資料
          setExcludingId(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}