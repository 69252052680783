import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import Typography from '@mui/material/Typography'
import { BackdropContext } from '../context/backdrop'
import { SnackbarContext } from '../context/snackbar'
import { Customer } from '../parse-class/Customer'

type AutocompletePropsType = Omit<AutocompleteProps<Customer, false, false, false>, 'options' | 'getOptionLabel' | 'isOptionEqualToValue' | 'renderOption' | 'renderInput'>
export interface IProps extends AutocompletePropsType {
  textFieldProps?: TextFieldProps
}

export default function CustomerAutocomplete(props: IProps) {

  const { textFieldProps, ...autocompleteProps } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [customerList, setCustomerList] = React.useState<Customer[] | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Customer)
      query.select('name')
      query.limit(50000)
      const data = await query.find()
      setCustomerList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  if (customerList === null) {
    return null
  }

  return (
    <Autocomplete
      options={customerList}
      getOptionLabel={customer => customer.get('name') || `未知的客戶${customer.id}`}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      // 因Label也會被當Key使用，可能會有重複Key的問題，這邊手動將key指定為ID，並將子內容設定為Label
      renderOption={(props, customer, state) =>
        // @ts-ignore
        <Typography {...props} key={customer.id} children={props.key} />
      }
      renderInput={autocompleteRenderInputParams =>
        <TextField
          {...autocompleteRenderInputParams}
          label="客戶"
          margin="normal"
          variant="outlined"
          {...props.textFieldProps}
        />
      }
      fullWidth
      autoHighlight
      {...autocompleteProps}
    />
  )
}