import React from 'react'
import { User } from '../parse-class/User'

interface Context {
  user?: User
  roles?: Parse.Role[]
  initUser: () => void
  login: (username: string, password: string) => Promise<User>
  logout: () => Promise<void>
  changePassword: (password: string) => Promise<void>
}

export const AuthContext = React.createContext<Context>({
  initUser: () => 0 as any,
  login: () => 0 as any,
  logout: () => 0 as any,
  changePassword: () => 0 as any,
});

export function AuthProvider(props: React.PropsWithChildren) {

  const [user, setUser] = React.useState<User | undefined>(undefined)
  const [roles, setRoles] = React.useState<Parse.Role[] | undefined>(undefined)

  /**
   * 看看是否已登入
   * （並處理INVALID_SESSION_TOKEN的問題）
   */
  const initUser = React.useCallback(async () => {
    const currentUser = Parse.User.current()
    // 發送請求，排除INVALID_SESSION_TOKEN問題
    if (currentUser) {
      try {
        const query = new Parse.Query(User)
        const user = await query.get(currentUser.id)
        setUser(user)
        const roles = await new Parse.Query(Parse.Role).findAll()
        setRoles(roles)
      } catch (e: any) {
        if (e.code === Parse.Error.INVALID_SESSION_TOKEN) {
          await Parse.User.logOut()
        } else {
          throw e
        }
      }
    }
  }, [])

  const login = React.useCallback(async (username: string, password: string) => {
    try {
      const user = await Parse.User.logIn(username, password)
      setUser(user)
      return user
    } catch (e) {
      throw e
    }
  }, [])

  const logout = React.useCallback(async () => {
    try {
      await Parse.User.logOut()
      setUser(undefined)
    } catch (e) {
      throw e
    }
  }, [])

  const changePassword = React.useCallback(async (password: string) => {
    if (user) {
      try {
        user.setPassword(password)
        await user.save()
      } catch (e) {
        throw e
      }
    }
  }, [user])

  return (
    <AuthContext.Provider value={{ user, roles, initUser, login, logout, changePassword }}>
      {props.children}
    </AuthContext.Provider>
  )
}