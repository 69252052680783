import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import { Customer } from '../../parse-class/Customer'

interface IProps extends DialogProps {
  customers: Customer[] | null
  filteredTags: string[]
  onUpdate: (filteredTags: string[]) => void
}

export default function FilterDialog(props: IProps) {

  const { customers, filteredTags, onUpdate, ...dialogProps } = props

  /**
   * 所有不重複的標籤
   */
  const tags = React.useMemo(() => {
    if (!customers) return []
    const tags = customers
      .filter(c => c.get('position') !== undefined)
      .flatMap(c => c.get('tags') || [])
    return [...new Set(tags)]
  }, [customers])

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>篩選客戶標籤</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormGroup>
            {tags.map(tag =>
              <FormControlLabel
                key={tag}
                label={tag === '' ? '（無標籤）' : tag}
                control={
                  <Checkbox
                    checked={filteredTags.includes(tag)}
                    onChange={e => {
                      if (e.target.checked) {
                        const arr = [...filteredTags, tag]
                        onUpdate(arr)
                      } else {
                        const index = filteredTags.indexOf(tag)
                        const arr = [...filteredTags]
                        arr.splice(index, 1)
                        onUpdate(arr)
                      }
                    }}
                  />
                }
              />
            )}
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onUpdate([])} color="primary">
          清除
        </Button>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}