import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import BasicTextField from '../../input/BasicTextField'
import { AuthContext } from '../../context/auth'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'

interface IFields {
  username: string
  password: string
}

export default function LoginDialog(props: DialogProps) {

  // context
  const { login } = React.useContext(AuthContext)
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // state
  const [fields, setFields] = React.useState<IFields>({
    username: '',
    password: '',
  });

  const vaild = React.useMemo(() => {
    return fields.username.trim().length > 0 && fields.password.trim().length > 0
  }, [fields])

  /**
   * init
   */
  React.useEffect(() => {
    if (props.open) {
      setFields({
        username: '',
        password: '',
      })
    }
  }, [props.open])

  const onLogin = React.useCallback(async () => {
    try {
      backdrop.show('登入中')
      const user = await login(fields.username, fields.password)
      snackbar.show(`哈囉，${user.get('name')}！`)
    } catch (e) {
      snackbar.show(`登入失敗：${e}`, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields])

  return (
    <React.Fragment>
      <Dialog {...props}>
        <DialogTitle>登入</DialogTitle>
        <DialogContent>
          <BasicTextField
            label="名字"
            value={fields.username}
            onChange={e => setFields(pre => ({ ...pre, username: e.target.value }))}
            autoFocus
            onFocus={e => e.target.select()} // 點擊自動全選
          />
          <BasicTextField
            label="密碼"
            value={fields.password}
            onChange={e => setFields(pre => ({ ...pre, password: e.target.value }))}
            type="password"
            onFocus={e => e.target.select()} // 點擊自動全選
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={!vaild} onClick={onLogin}>登入</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}