/**
 * 參考資料：合法寵物業者名單
 */
import { Customer } from '../Customer'

export interface IAttributes {
  argiId: string;                               // 序號
  city: string;                                 // 縣市
  name: string;                                 // 業者名稱
  address: string;                              // 連絡地址
  busitem: string;                              // 營業項目（A：繁殖、B：買賣、C：寄養）
  animalType: string;                           // 特定寵物種類（貓、狗、空白）
  validNum: string;                             // 許可證字號
  validdate: Date;                              // 許可證有效日期
  ownName: string;                              // 負責人姓名
  bosName: string;                              // 專任人員姓名
  rankYear: string;                             // 評鑑年度
  rankCode: 'A' | 'B' | 'C' | 'D';              // 評鑑字號（A：優等、B：甲等、C：乙等、D：丙等）
  rankFlag1: 'Y' | 'N' | '';                    // 評鑑資料顯示代碼（Y：確認、N：未確認）
  rankFlag2: 'Y' | 'N' | '';                    // 評鑑說明顯示代碼（Y：確認、N：未確認）
  rankText: string;                             // 評鑑說明
  stateFlag: '營業中' | '歇業' | '停業' | '廢止'  // 商家狀態代碼
  // 附加欄位
  coordinate: Parse.GeoPoint;
  customer: Customer;
  note: string;
  isExclude: boolean;
  isDelete: boolean;
}

export const CLASS_NAME = 'AgriPetBusinessData'

export class AgriPetBusinessData extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, AgriPetBusinessData)