import React from 'react'
import { HashRouter, Routes, Route } from "react-router-dom"
import { AuthContext } from '../context/auth'
import { App } from '../page/App'
import { CustomerPage } from '../page/CustomerPage'
import { MapCustomerPage } from '../page/MapCustomerPage'
import { GoogleMapPlacePage } from '../page/GoogleMapPlacePage'
import { AgriPetBusinessDataPage } from '../page/AgriPetBusinessDataPage'
import { VeterinaryDataPage } from '../page/VeterinaryDataPage'

export function Router() {

  const { user } = React.useContext(AuthContext)

  /**
   * 因為大部分頁面都會載入需要權限的資料，
   * 所以未登錄時只顯示APP框架和登入框，
   * 避免因抓取資料而顯示沒有權限的錯誤訊息
   */
  if (!user) {
    return (
      <HashRouter>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </HashRouter>
    )
  }

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<div>Hello</div>} />
          <Route path="customer" element={<CustomerPage />} />
          {/* <Route index element={<TransactionCustomerPage />} /> */}
          {/* <Route path="map-delivery" element={<MapDeliveryPage />} /> */}
          <Route path="map-customer" element={<MapCustomerPage />} />
          <Route path="google_map_place" element={<GoogleMapPlacePage />} />
          <Route path="agriPetBusinessData" element={<AgriPetBusinessDataPage />} />
          <Route path="veterinaryData" element={<VeterinaryDataPage />} />
          {/* <Route path="provider" element={<ProviderPage />} />
          <Route path="item" element={<ItemPage />} />
          <Route path="transaction_provider" element={<TransactionProviderPage />} />
          <Route path="account_provider" element={<AccountProviderPage />} />
          <Route path="purchase_helper" element={<PurchaseHelperPage />} />
          <Route path="transaction_customer" element={<TransactionCustomerPage />} />
          <Route path="account_customer" element={<AccountCustomerPage />} />
          <Route path="report/daily_transaction" element={<ReportDailyTransactionPage />} />
          <Route path="inventory_adjustment" element={<InventoryAdjustmentPage />} />
          <Route path="inventory_unsalable" element={<InventoryUnsalablePage />} />
          <Route path="google_map_place" element={<GoogleMapPlacePage />} />
          <Route path="sales_quotation" element={<SalesQuotationPage />} />
          <Route path="marketing_note" element={<MarketingNotePage />} />
          <Route path="marketing_sample" element={<MarketingSamplePage />} />
          <Route path="tax_entity" element={<TaxEntityPage />} />
          <Route path="uniform_invoice" element={<UniformInvoicePage />} />
          <Route path="electronic_invoice" element={<ElectronicInvoicePage />} />
          <Route path="z_item" element={<ZItemPage />} />
          <Route path="z_company" element={<ZCompanyPage />} />
          <Route path="pos_printer" element={<PosPrinterPage />} />
          <Route path="client_preference" element={<ClientPreferencePage />} /> */}
        </Route>
      </Routes>
    </HashRouter>
  )
}