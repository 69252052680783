import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/**
 * 標準框
 */
export function StandardBox(props: BoxProps) {

  return (
    <Box
      sx={theme => ({
        // Box原本背景為透明，在此改為和paper同色（白色）
        backgroundColor: theme.palette.background.paper
      })}
      p={2}
      borderRadius="borderRadius"
      boxShadow={1}
      {...props}
    />
  )
}

/**
 * 標準框的標題
 */
export function StandardBoxTitle(props: { title: string }) {

  return (
    <Typography variant="h6">
      {props.title}
    </Typography>
  )
}