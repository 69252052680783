/**
 * 參考資料：獸醫師(佐)開業執照
 */
import { Customer } from '../Customer'

export interface IAttributes {
  city: string;                           // 縣市
  code: string;                           // 字號
  type: '獸醫佐' | '獸醫師';               // 執照類別
  state: '開業' | '補發';                  // 狀態
  name: string;                           // 機構名稱
  owner: string;                          // 負責獸醫
  phone: string;                          // 機構電話
  date: Date;                             // 發照日期
  address: string;                        // 機構地址
  // 附加欄位
  coordinate: Parse.GeoPoint;
  customer: Customer;
  note: string;
  isExclude: boolean;
  isDelete: boolean;
}

export const CLASS_NAME = 'VeterinaryData'

export class VeterinaryData extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, VeterinaryData)