import React from 'react'
import { DataGridPremium, GridActionsCellItem, GridColDef } from '@mui/x-data-grid-premium'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { StandardBox, StandardBoxTitle } from '../../component/standard-component'
import { AgriPetBusinessData } from '../../parse-class/AgriPetBusinessData'
import CustomerAddDialog from './CustomerAddDialog'
import EditDialog from './EditDialog'
import ExcludeDialog from './ExcludeDialog'

export const FIELDS = {
  CUSTOMER: 'CUSTOMER',
  IS_EXCLUDE: 'IS_EXCLUDE',
  IS_DELETE: 'IS_DELETE',
  NAME: 'NAME',
  CITY: 'CITY',
  STATE: 'STATE',
  BUSITEM: 'BUSITEM',
  ANIMAL_TYPE: 'ANIMAL_TYPE',
  NOTE: 'NOTE',
}

export function AgriPetBusinessDataPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [dataList, setDataList] = React.useState<AgriPetBusinessData[] | null>(null)
  // state
  const [addingId, setAddingId] = React.useState<string | null>(null)
  const [editingId, setEditingId] = React.useState<string | null>(null)
  const [excludingId, setExcludingId] = React.useState<string | null>(null)

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(AgriPetBusinessData)
      query.limit(10000)
      query.ascending('argiId')
      const data = await query.find()
      setDataList(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  const rows = React.useMemo(() => {
    if (!dataList) {
      return []
    }
    return dataList.map(data => {
      return {
        id: data.id,
        [FIELDS.CUSTOMER]: data.get('customer'),
        [FIELDS.IS_EXCLUDE]: data.get('isExclude'),
        [FIELDS.IS_DELETE]: data.get('isDelete'),
        [FIELDS.NAME]: data.get('name'),
        [FIELDS.CITY]: data.get('city'),
        [FIELDS.STATE]: data.get('stateFlag'),
        [FIELDS.BUSITEM]: data.get('busitem'),
        [FIELDS.ANIMAL_TYPE]: data.get('animalType'),
        [FIELDS.NOTE]: data.get('note'),
      }
    })
  }, [dataList])

  const columns: GridColDef[] = React.useMemo(() => {
    return [
      {
        field: FIELDS.CUSTOMER,
        headerName: '建檔',
        type: 'boolean',
        width: 50,
        valueGetter: (value) => {
          return value !== undefined
        },
      },
      {
        field: FIELDS.IS_EXCLUDE,
        headerName: '排除',
        type: 'boolean',
        width: 50,
      },
      {
        field: FIELDS.IS_DELETE,
        headerName: '刪除',
        type: 'boolean',
        width: 50,
      },
      {
        field: FIELDS.NAME,
        headerName: '名稱',
        width: 200,
      },
      {
        field: FIELDS.CITY,
        headerName: '縣市',
        width: 85,
      },
      {
        field: FIELDS.STATE,
        headerName: '狀態',
        width: 85,
      },
      {
        field: FIELDS.BUSITEM,
        headerName: '營業項目',
        width: 100,
      },
      {
        field: FIELDS.ANIMAL_TYPE,
        headerName: '寵物種類',
        width: 100,
      },
      {
        field: FIELDS.NOTE,
        headerName: '備註',
        width: 200,
      },
      {
        field: 'actions',
        headerName: '操作',
        type: 'actions',
        width: 100,
        getActions: ({ id, row }) => {
          const customer: string = row[FIELDS.CUSTOMER]
          const isDelete: boolean = row[FIELDS.IS_DELETE]
          const isExclude: boolean = row[FIELDS.IS_EXCLUDE]
          return [
            <GridActionsCellItem
              disabled={customer !== undefined || isDelete || isExclude}
              icon={<AddIcon />}
              label="建立"
              color="primary"
              onClick={() => {
                setAddingId(id as string)
              }}
            />,
            <GridActionsCellItem
              disabled={customer !== undefined || isDelete || isExclude}
              icon={<NotInterestedIcon />}
              label="排除"
              color="primary"
              onClick={() => { setExcludingId(id as string) }}
            />,
            <GridActionsCellItem
              icon={<EditIcon />}
              label="編輯"
              color="primary"
              onClick={() => { setEditingId(id as string) }}
            />
          ]
        }
      }
    ]
  }, [])

  if (dataList === null) {
    return null
  }

  return (
    <React.Fragment>
      <Container maxWidth="lg" sx={{ pt: 2 }}>
        <StandardBox>
          <StandardBoxTitle title="特寵業者名單" />
          <Box mt={2} mb={1} height="80vh">
            <DataGridPremium
              rows={rows}
              columns={columns}
              density="compact"
              pagination
              autoPageSize
              disableRowSelectionOnClick
            />
          </Box>
        </StandardBox>
      </Container>
      <CustomerAddDialog
        open={addingId !== null}
        onClose={() => { setAddingId(null) }}
        fullWidth
        maxWidth="sm"
        addingPlaceId={addingId}
        onUpdate={() => {
          loadData()           // 載入新資料
          setAddingId(null)    // 關閉視窗
        }}
      />
      <EditDialog
        id={editingId}
        onClose={() => { setEditingId(null) }}
        onChange={() => {
          loadData()          // 載入新資料
          setEditingId(null)  // 關閉視窗
        }}
      />
      <ExcludeDialog
        id={excludingId}
        onClose={() => { setExcludingId(null) }}
        onChange={() => {
          loadData()            // 載入新資料
          setExcludingId(null)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}