import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import { Customer } from '../../parse-class/Customer'

interface IProps extends DialogProps {
  customers: Customer[] | null
}

export default function OutOfMapDialog(props: IProps) {

  const { customers, ...dialogProps } = props

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>不在地圖上的客戶</DialogTitle>
      <DialogContent>
        <List>
          {customers?.map(customer => (
            <ListItem key={customer.id} button>
              <ListItemText primary={customer.get('name')} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          知道了
        </Button>
      </DialogActions>
    </Dialog>
  )
}