/**
 * 參考資料：Google Map
 */
import { Customer } from '../Customer'

export interface IAttributes {
  placeId: string;
  name: string;
  businessStatus: string;
  city: string;
  district: string;
  address: string;
  types: string[];
  rating: number;
  userRatingsTotal: number;
  // 附加欄位
  coordinate: Parse.GeoPoint;
  customer: Customer;
  note: string;
  isExclude: boolean;
  isDelete: boolean;
}

export const CLASS_NAME = 'GoogleMapPlace'

export class GoogleMapPlace extends Parse.Object<Partial<IAttributes>> {

  constructor() {
    super(CLASS_NAME, {})
  }
}

Parse.Object.registerSubclass(CLASS_NAME, GoogleMapPlace)