import React from 'react'
import { Link } from "react-router-dom"
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
// import ElectricCarIcon from '@mui/icons-material/ElectricCar'
import MapIcon from '@mui/icons-material/Map'
import PetsIcon from '@mui/icons-material/Pets'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
// import LocalShippingIcon from '@mui/icons-material/LocalShipping'
// import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt'
// import CompareArrowsIcon from '@mui/icons-material/CompareArrows'
// import PrintIcon from '@mui/icons-material/Print'
// import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
// import ReceiptIcon from '@mui/icons-material/Receipt'
// import PlaceIcon from '@mui/icons-material/Place'
// import BubbleChartIcon from '@mui/icons-material/BubbleChart'
// import CodeIcon from '@mui/icons-material/Code'
// import StorefrontIcon from '@mui/icons-material/Storefront'
// import BeenhereIcon from '@mui/icons-material/Beenhere'
// import BoltIcon from '@mui/icons-material/Bolt'
// import CropIcon from '@mui/icons-material/Crop'
// import SettingsIcon from '@mui/icons-material/Settings'
// import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew'
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch'
// import ScienceIcon from '@mui/icons-material/Science'
// import Authentication from './Authentication'
// import { AuthContext } from '../../context/auth'

interface IProps {
  onClick: () => void // 點擊任意連結（目前用來關閉Drawer）
}

/**
 * 側邊導覽列
 */
export default function NavList(props: IProps) {

  const { onClick } = props

  // const { roles } = React.useContext(AuthContext)

  // const invoiceAuthentication = React.useMemo(() => {
  //   return roles?.find(role => role.get('name') === 'Invoice') !== undefined
  // }, [roles])

  // const zAuthentication = React.useMemo(() => {
  //   return roles?.find(role => role.get('name') === 'ZAnalysis') !== undefined
  // }, [roles])

  return (
    <List>
      {/* <ListItem button component={Link} to="/map-delivery" onClick={onClick}>
        <ListItemIcon><ElectricCarIcon /></ListItemIcon>
        <ListItemText primary="送貨助手" />
      </ListItem> */}
      <ListItemButton component={Link} to="/map-customer" onClick={onClick}>
        <ListItemIcon><MapIcon /></ListItemIcon>
        <ListItemText primary="客戶地圖" />
      </ListItemButton>
      <Divider />
      <ListItemButton component={Link} to="/customer" onClick={onClick}>
        <ListItemIcon><PetsIcon /></ListItemIcon>
        <ListItemText primary="客戶" />
      </ListItemButton>
      <Divider />
      <ListItemButton component={Link} to="/google_map_place" onClick={onClick}>
        <ListItemIcon><TextSnippetIcon /></ListItemIcon>
        <ListItemText primary="Google Map Place" />
      </ListItemButton>
      <ListItemButton component={Link} to="/agriPetBusinessData" onClick={onClick}>
        <ListItemIcon><TextSnippetIcon /></ListItemIcon>
        <ListItemText primary="特寵業者名單" />
      </ListItemButton>
      <ListItemButton component={Link} to="/veterinaryData" onClick={onClick}>
        <ListItemIcon><TextSnippetIcon /></ListItemIcon>
        <ListItemText primary="獸醫師(佐)開業執照" />
      </ListItemButton>
      {/* <ListItem button component={Link} to="/provider" onClick={onClick}>
        <ListItemIcon><LocalShippingIcon /></ListItemIcon>
        <ListItemText primary="供應商" />
      </ListItem>
      <ListItem button component={Link} to="/item" onClick={onClick}>
        <ListItemIcon><ThumbUpAltIcon /></ListItemIcon>
        <ListItemText primary="貨物" />
      </ListItem> */}
      {/* <Divider />
      <ListItem button component={Link} to="/transaction_provider" onClick={onClick}>
        <ListItemIcon><CompareArrowsIcon /></ListItemIcon>
        <ListItemText primary="供應商交易" />
      </ListItem>
      <ListItem button component={Link} to="/account_provider" onClick={onClick}>
        <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
        <ListItemText primary="供應商帳款" />
      </ListItem>
      <ListItem button component={Link} to="/purchase_helper" onClick={onClick}>
        <ListItemIcon><AccessibilityNewIcon /></ListItemIcon>
        <ListItemText primary="採購助手" />
      </ListItem> */}
      {/* <Divider />
      <ListItem button component={Link} to="/transaction_customer" onClick={onClick}>
        <ListItemIcon><CompareArrowsIcon /></ListItemIcon>
        <ListItemText primary="客戶交易" />
      </ListItem>
      <ListItem button component={Link} to="/account_customer" onClick={onClick}>
        <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
        <ListItemText primary="客戶帳款" />
      </ListItem>
      <ListItem button component={Link} to="/report/daily_transaction" onClick={onClick}>
        <ListItemIcon><BeenhereIcon /></ListItemIcon>
        <ListItemText primary="交易日報表" />
      </ListItem>
      <Divider />
      <ListItem button component={Link} to="/inventory_adjustment" onClick={onClick}>
        <ListItemIcon><CropIcon /></ListItemIcon>
        <ListItemText primary="庫存調整" />
      </ListItem>
      <ListItem button component={Link} to="/inventory_unsalable" onClick={onClick}>
        <ListItemIcon><RocketLaunchIcon /></ListItemIcon>
        <ListItemText primary="呆貨清單" />
      </ListItem> */}
      {/* <Divider />
      <ListItem button component={Link} to="/sales_quotation" onClick={onClick}>
        <ListItemIcon><ReceiptIcon /></ListItemIcon>
        <ListItemText primary="報價單" />
      </ListItem>
      <ListItem button component={Link} to="/marketing_note" onClick={onClick}>
        <ListItemIcon><StorefrontIcon /></ListItemIcon>
        <ListItemText primary="行銷行動" />
      </ListItem>
      <ListItem button component={Link} to="/marketing_sample" onClick={onClick}>
        <ListItemIcon><BubbleChartIcon /></ListItemIcon>
        <ListItemText primary="樣品配送" />
      </ListItem>
      <Divider /> */}
      {/* <Authentication authentication={invoiceAuthentication}>
        <ListItem button component={Link} to="/tax_entity" onClick={onClick}>
          <ListItemIcon><CodeIcon /></ListItemIcon>
          <ListItemText primary="抬頭統編" />
        </ListItem>
        <ListItem button component={Link} to="/uniform_invoice" onClick={onClick}>
          <ListItemIcon><ReceiptIcon /></ListItemIcon>
          <ListItemText primary="統一發票" />
        </ListItem>
        <ListItem button component={Link} to="/electronic_invoice" onClick={onClick}>
          <ListItemIcon><BoltIcon /></ListItemIcon>
          <ListItemText primary="電子發票" />
        </ListItem>
      </Authentication> */}
      {/* <Authentication authentication={zAuthentication}>
        <Divider />
        <ListItem button component={Link} to="/z_item" onClick={onClick}>
          <ListItemIcon><ScienceIcon /></ListItemIcon>
          <ListItemText primary="貨物分析" />
        </ListItem>
        <ListItem button component={Link} to="/z_company" onClick={onClick}>
          <ListItemIcon><ScienceIcon /></ListItemIcon>
          <ListItemText primary="公司分析" />
        </ListItem>
      </Authentication> */}
      {/* <Divider />
      <ListItem button component={Link} to="/pos_printer" onClick={onClick}>
        <ListItemIcon><PrintIcon /></ListItemIcon>
        <ListItemText primary="出單機" />
      </ListItem>
      <ListItem button component={Link} to="/client_preference" onClick={onClick}>
        <ListItemIcon><SettingsIcon /></ListItemIcon>
        <ListItemText primary="偏好設定" />
      </ListItem> */}
    </List>
  )
}