import React from 'react'
import Dialog, { DialogProps } from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Button from '@mui/material/Button'
import { Customer } from '../../parse-class/Customer'

interface IProps extends DialogProps {
  customers: Customer[] | null
  onSelectCustomer?: (customer: Customer) => void
}

export default function SelectCustomerDialog(props: IProps) {

  const { customers, onSelectCustomer, ...dialogProps } = props

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>選擇客戶</DialogTitle>
      <DialogContent>
        <List>
          {customers?.map(customer => (
            <ListItem
              key={customer.id}
              button
              onClick={() => {
                onSelectCustomer && onSelectCustomer(customer)
              }}
            >
              <ListItemText
                primary={customer?.get('name') || '未知的客戶'}
                secondary={customer?.get('tags')?.join(', ') || ''}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => { dialogProps.onClose && dialogProps.onClose({}, 'backdropClick') }}
        >
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}