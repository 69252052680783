import React from 'react'
import FormControl, { FormControlProps } from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectProps } from '@mui/material/Select'
import OutlinedInput from "@mui/material/OutlinedInput"

export type IProps = SelectProps & {
  formProps?: FormControlProps
}

export default function BasicSelect(props: IProps) {

  const { formProps, ...selectProps } = props;
  const shrink = selectProps?.value !== null; // 修復value和label重疊的問題

  return (
    <FormControl
      margin="normal"
      variant="outlined"
      fullWidth
      {...formProps}
    >
      <InputLabel shrink={shrink}>{selectProps.label}</InputLabel>
      <Select
        input={<OutlinedInput label={selectProps.label} notched={shrink} />}
        label={selectProps.label}
        displayEmpty
        {...selectProps}
      >
        {selectProps.children}
      </Select>
    </FormControl>
  )
}