import './index.css'
import React from 'react'
import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from "@googlemaps/markerclusterer"
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Fab from '@mui/material/Fab'
import MenuIcon from '@mui/icons-material/Menu'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import FullHeightBox from '../../component/FullHeightBox'
import CustomerEditDialog from '../CustomerPage/EditDialog'
import SelectCustomerDialog from './SelectCustomerDialog'
import CustomerInfoDialog from './CustomerInfoDialog'
import OutOfMapDialog from './OutOfMapDialog'
import FilterDialog from './FilterDialog'
import CustomerTagBatchDialog from './CustomerTagBatchDialog'
import { Customer } from '../../parse-class/Customer'

export function MapCustomerPage() {

  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  // data
  const [customers, setCustomers] = React.useState<Customer[] | null>(null)
  // state
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [myPosition, setMyPosition] = React.useState<GeolocationPosition | null>(null)            // 目前位置
  const [selectingCustomers, setSelectingCustomers] = React.useState<Customer[] | null>(null)     // 對話框：從多個客戶選一個
  const [showCustomerInfo, setShowCustomerInfo] = React.useState<Customer | null>(null)
  const [editingCustomerId, setEditingCustomerId] = React.useState<string | null>(null)
  const [showFilterDialog, setShowFilterDialog] = React.useState(false)                           // 對話框：過濾設定
  const [showOutOfMapDialog, setShowOutOfMapDialog] = React.useState(false)                       // 對話框：沒標示在地圖上的客戶
  const [showBatchTagDialog, setShowBatchTagDialog] = React.useState(false)                       // 對話框：批量增刪標籤
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)                        // Menu選單
  const [filteredTags, setFilteredTags] = React.useState<string[]>([])
  // Google Map Library
  const [CoreLibrary, setCoreLibrary] = React.useState<google.maps.CoreLibrary | null>(null)
  const [MapsLibrary, setMapsLibrary] = React.useState<google.maps.MapsLibrary | null>(null)
  const [MarkerLibrary, setMarkerLibrary] = React.useState<google.maps.MarkerLibrary | null>(null)

  /**
   * Load Google Map Library
   */
  React.useEffect(() => {
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
      version: "weekly",
      // libraries: ["places"]
    })
    async function fn() {
      const CoreLibrary = await loader.importLibrary('core')
      setCoreLibrary(CoreLibrary)
      const MapsLibrary = await loader.importLibrary('maps')
      setMapsLibrary(MapsLibrary)
      const MarkerLibrary = await loader.importLibrary('marker')
      setMarkerLibrary(MarkerLibrary)
    }
    fn()
  }, [])

  const initMap = React.useCallback(async () => {
    if (!CoreLibrary || !MapsLibrary) {
      return
    }
    const mapOptions: google.maps.MapOptions = {
      mapId: 'DEMO_MAP_ID',                                                    // 2024年新功能：Map ID is required for advanced markers.
      center: { lat: 22.694979429263405, lng: 120.35530365918893 },
      zoom: 15,
      minZoom: 8,
      fullscreenControl: false,                                                // 禁用全螢幕按鈕
      mapTypeControl: false,                                                   // 禁用衛星圖切換
      streetViewControl: false,                                                // 禁用街景
      zoomControlOptions: { position: CoreLibrary.ControlPosition.TOP_RIGHT }, // 縮放工具位於右上角
    }
    const map = new MapsLibrary.Map(document.getElementById('map')!, mapOptions)
    setMap(map)
  }, [CoreLibrary, MapsLibrary])

  React.useLayoutEffect(() => {
    initMap()
  }, [initMap])

  const loadData = React.useCallback(async () => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(Customer)
      query.limit(10000)
      const data = await query.find()
      setCustomers(data)
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    loadData()
  }, [loadData])

  /**
   * 篩選要顯示的客戶
   */
  const showCustomers = React.useMemo(() => {
    if (!customers) {
      return []
    }
    // 找出有座標的客戶
    const customersOnMap = customers.filter(c => c.get('position') !== undefined)
    // 沒有任何選擇，就不過濾
    if (filteredTags.length === 0) {
      return [...customersOnMap]
    }
    // 是否選擇無標籤
    const noTag = filteredTags.includes('')
    // 根據標籤過濾
    return customersOnMap.filter(customer =>
      (noTag && customer.get('tags')?.length === 0) || filteredTags.some(tag => tag.length > 0 && customer.get('tags')?.includes(tag))
    )
  }, [customers, filteredTags])

  /**
   * 沒有座標的客戶
   */
  const outOfMapCustomers = React.useMemo(() => {
    if (!customers) {
      return []
    }
    return customers.filter(c => c.get('position') === undefined)
  }, [customers])

  /**
   * 更新自己目前的定位
   */
  const updateMyPosition = React.useCallback(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        setMyPosition(position)
        // 將目前位置設為地圖中心（如果地圖已載入完成）
        map && map.setCenter({ lat: position.coords.latitude, lng: position.coords.longitude })
      })
    } else {
      alert('無法使用定位功能')
    }
  }, [map])

  React.useEffect(() => {
    updateMyPosition()
  }, [updateMyPosition])

  /**
   * 顯示自己位置
   * 
   * Customize advanced markers：
   * https://developers.google.com/maps/documentation/javascript/examples/advanced-markers-basic-style
   */
  React.useEffect(() => {
    if (!MarkerLibrary) {
      return
    }
    if (map && myPosition) {
      const pin = new MarkerLibrary.PinElement({
        scale: 1.2,
      })
      const marker = new MarkerLibrary.AdvancedMarkerElement({
        map: map,
        position: { lat: myPosition.coords.latitude, lng: myPosition.coords.longitude },
        title: '目前位置',
        content: pin.element
      });
      return () => {
        marker.remove()
      }
    }
  }, [MarkerLibrary, map, myPosition])

  /**
   * 顯示客戶標記
   */
  React.useEffect(() => {
    if (!MarkerLibrary) {
      return
    }
    if (map) {
      const markers = showCustomers.map(customer => {
        const position = customer.get('position')!
        const glyphLabel = document.createElement("div");
        glyphLabel.className = 'glyph-label';
        glyphLabel.textContent = customer.get('name')?.substring(0, 12) || '';
        const marker = new MarkerLibrary.AdvancedMarkerElement({
          position: { lat: position.latitude, lng: position.longitude },
          title: customer.get('name'),
          content: glyphLabel
        });
        marker.setAttribute('customerId', customer.id)
        marker.addListener('click', () => {
          setShowCustomerInfo(customer)
        })
        return marker
      })
      const clusterer = new MarkerClusterer({
        markers,
        map,
        onClusterClick: (e, cluster, map) => {
          const markers = cluster.markers || [cluster.marker]
          const customers = markers.map(marker => {
            // @ts-ignore
            const customerId = marker.getAttribute('customerId')
            const customer = showCustomers.find(c => c.id === customerId)!
            return customer
          })
          setSelectingCustomers(customers)
          e.stop()
        }
      })
      return () => {
        clusterer.setMap(null)
      }
    }
  }, [MarkerLibrary, map, showCustomers])

  return (
    <React.Fragment>
      <FullHeightBox>
        <div id="map" style={{ height: '100%' }} />
      </FullHeightBox>
      <Fab
        size="medium"
        color="primary"
        style={{ position: 'fixed', right: 24, bottom: 24 }}
        onClick={e => setAnchorEl(e.currentTarget)}
      >
        <MenuIcon />
      </Fab>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => { setShowFilterDialog(true); setAnchorEl(null) }}>篩選客戶標籤</MenuItem>
        <MenuItem onClick={() => { setShowOutOfMapDialog(true); setAnchorEl(null) }}>沒有座標的客戶</MenuItem>
        <MenuItem onClick={() => { setShowBatchTagDialog(true); setAnchorEl(null) }}>批次增刪標籤</MenuItem>
        <MenuItem onClick={() => { updateMyPosition(); setAnchorEl(null) }}>重新定位</MenuItem>
      </Menu>
      <SelectCustomerDialog
        customers={selectingCustomers}
        open={selectingCustomers !== null}
        onClose={() => setSelectingCustomers(null)}
        fullWidth
        maxWidth="xs"
        onSelectCustomer={customer => {
          setSelectingCustomers(null)
          setShowCustomerInfo(customer)
        }}
      />
      <CustomerInfoDialog
        customer={showCustomerInfo}
        open={showCustomerInfo !== null}
        onClose={() => setShowCustomerInfo(null)}
        fullWidth
        maxWidth="xs"
        onClickEdit={() => {
          setEditingCustomerId(showCustomerInfo!.id) // 開啟編輯視窗
          setShowCustomerInfo(null)                  // 關閉預覽視窗
        }}
      />
      <CustomerEditDialog
        id={editingCustomerId}
        onClose={() => { setEditingCustomerId(null) }}
        onChange={async () => {
          await loadData()            // 更新資料
          setEditingCustomerId(null)  // 關閉視窗
        }}
      />
      <OutOfMapDialog
        customers={outOfMapCustomers}
        open={showOutOfMapDialog}
        onClose={() => setShowOutOfMapDialog(false)}
        fullWidth
        maxWidth="xs"
      />
      <FilterDialog
        customers={customers}
        open={showFilterDialog}
        onClose={() => setShowFilterDialog(false)}
        fullWidth
        maxWidth="xs"
        filteredTags={filteredTags}
        onUpdate={filteredTags => { setFilteredTags(filteredTags) }}
      />
      <CustomerTagBatchDialog
        open={showBatchTagDialog}
        onClose={() => setShowBatchTagDialog(false)}
        fullWidth
        maxWidth="xs"
        onChange={async () => {
          await loadData()              // 更新資料
          setShowBatchTagDialog(false)  // 關閉視窗
        }}
      />
    </React.Fragment>
  )
}