import React from 'react'
import { Outlet } from "react-router-dom"
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import MenuIcon from '@mui/icons-material/Menu'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { AuthContext } from '../../context/auth'
import NavList from './NavList'
import LoginDialog from './LoginDialog'
import UpdatePasswordDialog from './UpdatePasswordDialog'


const DRAWER_WIDTH = 270

export function App() {

  // context
  const { user, initUser, logout } = React.useContext(AuthContext)
  // state
  const [showDrawer, setShowDrawer] = React.useState(false)
  const [profileMenuAnchorEl, setProfileMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const [showChangePasswordDialog, setShowChangePasswordDialog] = React.useState(false)

  const showLoginDialog = React.useMemo(() => {
    return user === undefined
  }, [user])

  React.useEffect(() => {
    initUser()
  }, [initUser])

  const onLogout = React.useCallback(() => {
    logout()
  }, [logout])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        {/* App Bar */}
        <AppBar position="fixed" sx={theme => ({ zIndex: theme.zIndex.drawer + 1 })}>
          <Toolbar>
            {/* Menu按鈕，在lg以上（含）隱藏 */}
            <IconButton
              sx={{ display: { xs: 'inherit', lg: 'none' } }}
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => { setShowDrawer(true) }}
            >
              <MenuIcon />
            </IconButton>
            {/* 標題 */}
            <Typography variant="h6" noWrap sx={{ flexGrow: 1, letterSpacing: 2 }} >
              CATION
            </Typography>
            {/* Auth */}
            {user &&
              <Button size="medium" color="inherit" startIcon={<AccountCircleIcon />} onClick={e => setProfileMenuAnchorEl(e.currentTarget)}>
                {user.get('name')}
              </Button>
            }
          </Toolbar>
        </AppBar>
        {/* 永久性Drawer，在lg以上（含）顯示 */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'inherit' },
            width: DRAWER_WIDTH,
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <NavList onClick={() => setShowDrawer(false)} />
          </Box>
        </Drawer>
        {/* 暫時性Drawer，在lg以下隱藏 */}
        <Drawer
          variant="temporary"
          sx={theme => ({
            display: { xs: 'inherit', lg: 'none' },
            width: DRAWER_WIDTH,
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH },
            zIndex: theme.zIndex.drawer + 2,
          })}
          open={showDrawer}
          onClose={() => { setShowDrawer(false) }}
        >
          <NavList onClick={() => setShowDrawer(false)} />
        </Drawer>
        {/* Main */}
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}>
          <Toolbar />
          <Outlet />
        </Box>
      </Box>
      {/* Profile Menu */}
      <Menu
        anchorEl={profileMenuAnchorEl}
        keepMounted
        open={Boolean(profileMenuAnchorEl)}
        onClose={() => setProfileMenuAnchorEl(null)}
      >
        <MenuItem onClick={() => { setProfileMenuAnchorEl(null); onLogout() }}>登出</MenuItem>
        <MenuItem onClick={() => { setProfileMenuAnchorEl(null); setShowChangePasswordDialog(true) }}>變更密碼</MenuItem>
      </Menu>
      {/* Login Dialog */}
      <LoginDialog open={showLoginDialog} />
      {/* Update Password Dialog */}
      <UpdatePasswordDialog
        open={showChangePasswordDialog}
        onUpdate={() => { setShowChangePasswordDialog(false) }}
        onClose={() => { setShowChangePasswordDialog(false) }}
      />
    </React.Fragment>
  )
}
