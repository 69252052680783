import Parse from 'parse'

const PARSE_APPLICATION_ID = process.env.REACT_APP_PARSE_APPLICATION_ID
const PARSE_JAVASCRIPT_KEY = process.env.REACT_APP_PARSE_JAVASCRIPT_KEY
const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_SERVER_URL

if (PARSE_APPLICATION_ID && PARSE_JAVASCRIPT_KEY && PARSE_SERVER_URL) {
  Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY)
  Parse.serverURL = PARSE_SERVER_URL
  global.Parse = Parse
} else {
  alert('Parse 參數錯誤')
}
