import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { BackdropContext } from '../../context/backdrop'
import { SnackbarContext } from '../../context/snackbar'
import { useCheckMobile } from '../../context/user-check-mobile'
import BasicTextField from '../../input/BasicTextField'
import CustomerAutocomplete from '../../input/CustomerAutocomplete'
import { AgriPetBusinessData } from '../../parse-class/AgriPetBusinessData'
import { Customer } from '../../parse-class/Customer'

interface IFields {
  argiId: string;
  name: string;
  address: string;
  ownName: string;
  bosName: string;
  customer: Customer | null;
  note: string;
  lat: number;
  lng: number;
  isExclude: boolean;
}

interface IParams {
  id: string | null;
  onClose: () => void;
  onChange: () => void;
}

export default function EditDialog(props: IParams) {

  const { id, onClose, onChange } = props
  // context
  const backdrop = React.useContext(BackdropContext)
  const snackbar = React.useContext(SnackbarContext)
  const isMobile = useCheckMobile()
  // state
  const [raw, setRaw] = React.useState<AgriPetBusinessData | null>(null)
  const [fields, setFields] = React.useState<IFields>({
    argiId: '',
    name: '',
    address: '',
    ownName: '',
    bosName: '',
    customer: null,
    note: '',
    lat: 0,
    lng: 0,
    isExclude: false,
  })

  const verify = React.useMemo(() => {
    return fields.name.trim().length > 0
  }, [fields])

  const loadData = React.useCallback(async (id: string) => {
    try {
      backdrop.show('讀取資料中')
      const query = new Parse.Query(AgriPetBusinessData)
      const data = await query.get(id)
      setRaw(data)
      setFields({
        argiId: data.get('argiId') || '',
        name: data.get('name') || '',
        address: data.get('address') || '',
        ownName: data.get('ownName') || '',
        bosName: data.get('bosName') || '',
        customer: data.get('customer')!,
        note: data.get('note') || '',
        lat: data.get('coordinate')?.latitude || 0,
        lng: data.get('coordinate')?.longitude || 0,
        isExclude: data.get('isExclude') || false
      })
    } catch (e: any) {
      snackbar.show('讀取資料失敗：' + e, 'error')
    } finally {
      backdrop.hide()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (id !== null) {
      loadData(id)
    }
  }, [id, loadData])

  const onSave = React.useCallback(async () => {
    if (id !== null && raw !== null && verify) {
      try {
        backdrop.show('更新資料中')
        if (fields.customer) {
          raw.set('customer', fields.customer)
        } else {
          raw.unset('customer')
        }
        raw.set('note', fields.note)
        const coordinate = new Parse.GeoPoint({ latitude: fields.lat, longitude: fields.lng })
        raw.set('coordinate', coordinate)
        raw.set('isExclude', fields.isExclude)
        await raw.save()
        snackbar.show('更新成功')
        onChange() // 通知外部資料有變更
      } catch (e: any) {
        snackbar.show('更新失敗：' + e, 'error')
      } finally {
        backdrop.hide()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, raw, verify, fields, onChange])

  // 等待從伺服器取得資料
  if (raw === null) {
    return null;
  }

  return (
    <Dialog
      open={id !== null}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={isMobile}
    >
      <DialogTitle>編輯資料</DialogTitle>
      <DialogContent>
        <CustomerAutocomplete
          value={fields.customer}
          onChange={(event, value) => setFields(pv => ({ ...pv!, customer: value }))}
          textFieldProps={{ label: "關聯客戶" }}
        />
        <BasicTextField
          label="備註"
          value={fields.note}
          onChange={e => { setFields(pv => ({ ...pv, note: e.target.value })) }}
        />
        <BasicTextField
          label="緯度"
          type="number"
          value={fields.lat}
          onChange={e => { setFields(pv => ({ ...pv, lat: Number(e.target.value) })) }}
          // @ts-ignore
          onWheel={e => { e.target.blur() }} // 避免滾輪改到經緯度
        />
        <BasicTextField
          label="經度"
          type="number"
          value={fields.lng}
          onChange={e => { setFields(pv => ({ ...pv, lng: Number(e.target.value) })) }}
          // @ts-ignore
          onWheel={e => { e.target.blur() }} // 避免滾輪改到經緯度 
        />
        <BasicTextField
          label="序號"
          value={fields.argiId}
          disabled
        />
        <BasicTextField
          label="名稱"
          value={fields.name}
          disabled
        />
        <BasicTextField
          label="地址"
          value={fields.address}
          disabled
        />
        <BasicTextField
          label="負責人"
          value={fields.ownName}
          disabled
        />
        <BasicTextField
          label="專任人員"
          value={fields.bosName}
          disabled
        />
      </DialogContent>
      <DialogActions>
        <FormControlLabel
          label="排除"
          control={
            <Checkbox
              checked={fields.isExclude}
              onChange={e => setFields(pv => ({ ...pv!, isExclude: e.target.checked }))}
              color="default"
            />
          }
        />
        <Button
          disabled={!verify}
          color="primary"
          onClick={onSave}
        >
          儲存
        </Button>
        <Button color="error" onClick={onClose}>
          關閉
        </Button>
      </DialogActions>
    </Dialog>
  )
}