import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

/**
 * Context
 */
interface IContext {
  show: (msg: string) => void
  hide: () => void
}
export const BackdropContext = React.createContext<IContext>({
  show: () => { },
  hide: () => { },
});

export function BackdropProvider(props: React.PropsWithChildren) {

  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState('');

  const show = React.useCallback((msg: string) => {
    setOpen(true)
    setMsg(msg)
  }, [])

  const hide = React.useCallback(() => {
    setOpen(false)
  }, [])

  return (
    <BackdropContext.Provider value={{ show, hide }}>
      {/* Children */}
      {props.children}
      {/* Backdrop */}
      <Backdrop open={open} sx={theme => ({
        zIndex: theme.zIndex.modal + 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      })}>
        <CircularProgress color="inherit" />
        <Typography variant="h6" color="inherit" sx={{ mt: 2 }}>{msg}</Typography>
      </Backdrop >
    </BackdropContext.Provider>
  )
}