import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import { twDistrict } from '../taiwan-district'

type AutocompletePropsType = Omit<AutocompleteProps<string, false, false, false>, 'options' | 'renderInput'>
export interface IProps extends AutocompletePropsType {
  city: string | null; // 目前所選的縣市
  textFieldProps?: TextFieldProps;
}

export default function DistrictAutocomplete(props: IProps) {

  const { city, textFieldProps, ...autocompleteProps } = props;
  // data
  const [districtList, setDistrictList] = React.useState<string[] | null>(null);

  /**
   * 讀取該縣市的鄉鎮市區清單
   */
  React.useEffect(() => {
    if (city === null || twDistrict[city] === undefined) {
      setDistrictList(null);
    } else {
      setDistrictList(twDistrict[city]);
    }
  }, [city])

  /**
   * 在取得資料之前，不顯示任何東西
   */
  if (districtList === null) {
    return (
      <TextField
        margin="normal"
        variant="outlined"
        label="鄉鎮市區"
        value="尚未選擇縣市"
        fullWidth
        {...props.textFieldProps}
      />
    )
  }

  return (
    <Autocomplete
      renderInput={autocompleteRenderInputParams =>
        <TextField
          {...autocompleteRenderInputParams}
          margin="normal"
          variant="outlined"
          label="鄉鎮市區"
          // 關閉Chrome的自動完成功能
          inputProps={{ ...autocompleteRenderInputParams.inputProps, autoComplete: "new-password" }}
          {...props.textFieldProps}
        />
      }
      options={districtList}
      getOptionLabel={district => district}
      fullWidth
      {...autocompleteProps}
    />
  )
}